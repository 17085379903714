.app__laurels_awards {
    display: flex;
    justify-content: flex-start;
    align-items: top;
    flex-wrap: wrap;
    width: 70%;
    margin-top: 3rem;
}

.app__laurels_awards-card {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;
    padding: 0 2rem 0 2rem;
    margin: 1rem;
}

@media screen and (min-width: 800px) {
    .app__laurels_awards {
        min-width: 580px;
    }
}

@media screen and (min-width: 900px) {
    .app__laurels_awards-card {
        min-width: 390px;
    }
}

.app__laurels_awards-card img {
    width: 50px;
    height: 50px;
}

.app__laurels_awards-card_content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

@media screen and (max-width: 1250px) {
    .app__laurels_awards-card {
        min-width: 50%;
        margin: 1rem 0;
        padding: 0 2rem 0 2rem;
    }
}

/* @media screen and (max-width: 850px) {
    .app__laurels_awards { width: 80% }
} */

@media screen and (max-width: 650px) {
    .app__laurels_awards { flex-direction: column; }
}

/* @media screen and (max-width: 450px) {
    .app__laurels_awards-card {
        min-width: 100%;
        margin: 1rem 0;
    }
} */
