.navspace {
    padding-top: 20px;
}

.app__header {
    background-color: var(--color-black);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 7vw;
    padding-right: 2rem;
    font-size: 5vw !important;
}

.app__wrapper_info p {
    padding-right: 2rem;
}

.app__wrapper_info {
   max-width: 49%;
   z-index: 1;
}

.app__wrapper1_img img{
    width: 100%;
    max-height: calc(100vh - 77px);
    /* height: calc(100vh - 74px - (5vw)); */
    /* margin-top: -75px; */
    /* object-fit: cover;
    border: 1px solid white;
    max-width: 100vh;
    min-width: 400px; */
  }

  .app__wrapper1_img{
    /* margin-top: 100px; */
    position: absolute;
    right: 3%;
    width: 50%;
    top: 77px;
    z-index: 0;
   /* object-fit: cover;
    width: 50%;
    height: calc(100vh - 100px); */
  }

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 500px) {
    .app__header-h1 {
        font-size: 20px !important;
    }

    .app__wrapper_info p {
        font-size: 10px !important;
    }
}


@media screen and (max-width: 850px) {
    .app__wrapper1_img {
      margin: 0 0 4rem 0;
      position: relative;
    }

    .app__wrapper_info {
        max-width: none;
     }

    .app__wrapper_info p {
        margin-top: 0 !important;
    }

    .app__header-h1 {
        font-size: 40px !important;
        line-height: 55px;
        margin-bottom: 10px;
    }

    .app__wrapper1_img img{
        width: 100%;
        height: auto;
        margin-top: 0;
        margin-bottom: -50px;
        object-fit: cover;
      }
    
    .app__wrapper1_img{
        object-fit: cover;
        width: 100%;
        height: auto;
    }
}