.back_button:hover {
    cursor: pointer;
}

.auth__form-container {
min-height: 100vh;
display: flex;
flex-direction: row;
}

.auth__form-container_fields {
width: 50%;
display: flex;
flex-direction: column;
justify-content: center;
position: relative;
top: 0;
height: 100vh;
padding: 2rem;
background: var(--color-golden);
}

.auth__form-container_image {
width: 50%;
display: flex;
box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.05);
max-height: 100vh;
}

.auth__form-container_image img {
width: 100%;
height: 100%;
object-fit: contain;
}


.auth__form-container_fields-content {
display: flex;
flex-direction: column;
justify-content: flex-start;

padding: 2rem;
box-shadow: 0px 1px 5px rgb(0 0 0 / 10%);
border-radius: 5px;
transition: 0.8s ease;
background: #fff;
}

.auth__form-container_fields-content p {
font-size: 1.5rem;
font-family: Arial, Helvetica, sans-serif;
color: black;
font-weight: 900;
}

.auth__form-container_fields-content_input {
display: flex;
flex-direction: column;
position: relative;

margin: 1rem 0rem;
}

.auth__form-container_fields-content_input label {
margin-bottom: 0.45rem;
color: black;
font-size: 12px;
font-family: Arial, Helvetica, sans-serif;
letter-spacing: 0.7px;
line-height: 1.3;
}

.auth__form-container_fields-content_input input {
padding: 0.55rem 0.4rem;
border: 1px solid rgb(184, 196, 194);
border-radius: 4px;
font-size: 14px;
outline: none;
transition: all 150ms ease-in-out 0s;
width: 85%;
background: #fff;
}

.auth__form-container_fields-content_input input::placeholder {
color: #b1b1b1;
width: 100%;
font-weight: unset;
font-family: Arial, Helvetica, sans-serif;
}

.auth__form-container_fields-content_input input:hover {
border-color: #dcdddd;
}

.auth__form-container_fields-content_input input:focus,
.auth__form-container_fields-content_input input:active {
box-shadow: 0px 0px 0px 1.5px var(--color-golden);;
border-color: var(--color-golden);;
}

.auth__form-container_fields-content_input-password {
position: absolute;
right: 13%;
top: 50%;
cursor: pointer;
}

.auth__form-container_fields-content_button {
margin-top: 2rem;
margin-bottom: 0.6rem;
display: flex;
justify-content: flex-start;
}

.auth__form-container_fields-content_button button {
border-radius: 4px;
background: var(--color-golden);
border: 1px solid var(--color-golden);;
color: #fff;
font-family: Arial, Helvetica, sans-serif;
font-weight: 500;
padding: 0.7rem 1.2rem;
outline: none;
cursor: pointer;
transition: 0.3s ease;
}

.auth__form-container_fields-content_button button:hover {
background: var(--color-golden);
}

.auth__form-container_fields-account {
display: flex;
justify-content: flex-start;
align-items: center;

margin-top: 0.2rem;
}

.auth__form-container_fields-account p {
font-size: 14px;
color: #000;
font-weight: 500;
}

.auth__form-container_fields-account span {
color: black;
cursor: pointer;
font-weight: 700;
}

@media screen and (max-width: 800px) {
.auth__form-container {
    flex-direction: column-reverse;
}

.auth__form-container_fields {
    justify-content: flex-start;
    width: 100%;
    position: absolute;
}

.auth__form-container_image {
    display: none;
}

}

@media screen and (max-width: 375px) {
.auth__form-container_fields {
    padding: 2rem 0.5rem;
}

.auth__form-container_fields-content_input input {
    width: 95%;
}

.auth__form-container_fields-content_input-password {
    right: 3%;
}
}